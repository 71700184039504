/* colors */
:root {
  --green-10: #00e5d2;
  --green-20: #00c8b8;
  --green-30: #00ac9e;
  --green-40: #008f83;
  --green-50: #007269;
  --green-60: #00564f;
  --green-70: #003935;
  --green-80: #001d1a;
}

html,
body {
  color: "#FFF";
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
}
/* colors */
.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
form {
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#paper-props::-webkit-scrollbar {
  width: 12px;
}

#paper-props:hover {
  overflow-y: overlay;
}
#paper-props::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
#paper-props::-webkit-scrollbar-corner {
  background-color: initial;
}
#paper-props::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
  color: #e5e6e7;
}
.App-header {
  min-height: 100vh;
  padding: 24px 50px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*-------- start attendee video style --------*/
.single-video-card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}
.single-video-card .talking-indicator-light {
  border: 2px solid #00c2b2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 10;
}

.single-video-card .name-indicator {
  position: absolute;
  z-index: 9;
  bottom: 0;
  width: 100%;
  padding-right: min(18px, 6%);
  padding-left: min(18px, 6%);
  padding-top: min(18px, 9%);
  padding-bottom: min(18px, 5%);
  background: rgb(254, 246, 243);
  background: linear-gradient(
    180deg,
    rgba(254, 246, 243, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.name-indicator .name {
  font-size: 16px;
}
.waiting-room-video {
  aspect-ratio: 16 / 9;
  width: auto !important;
}
/*-------- end attendee video style --------*/

/*-------- start meeting video gallery style --------*/
#meeting-gallery {
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#meeting-gallery.drawer-open {
  width: calc(100% - 350px);
  /* if drawer width changes, change this value */
}

#meeting-gallery .single-video-container {
  transition: width 0.4s linear;
  border-radius: 4px;
  padding: 8px;
}

.single-video-container {
  width: 100%;
  height: 100%;
}

.single-video-container.keep-ratio {
  aspect-ratio: 16 / 9;
  width: auto !important;
  max-width: 100%;
}

.single-video-container.pinned {
  width: 82.5% !important;
  aspect-ratio: 16 / 9;
}

#unpinned-gallery {
  width: 17.5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  padding-right: 8px;
  height: 100%;
  margin-left: auto;
}

.unpinned {
  height: 25% !important;
  max-height: 25%;
  min-height: 25%;
  width: 100%;
  border-radius: 4;
}

.unpinned .name-indicator .name {
  font-size: 14px;
}

#meeting-gallery .unpinned .single-video-container {
  height: 100% !important;
  width: 100%;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

/*-------- end meeting video gallery style  --------*/

/* START OTHERS TILE*/

.others-tile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.others-tile-inner {
  background: var(--green-70);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

/* END OTHERS TILE*/

.layout-radio-label {
  width: 100%;
}

@media (max-width: 900px) {
  .App-header {
    padding: 16px;
  }

  #meeting-gallery .single-video-container {
    padding: 4px;
  }

  .name-indicator .name {
    font-size: 12px;
    position: relative;
    bottom: unset;
    top: unset;
    left: unset;
  }
  .single-video-card .name-indicator {
    padding-bottom: 8px !important;
    padding: 12px;
  }
}
@media (max-width: 600px) {
  #unpinned-gallery {
    display: none;
  }
  .single-video-container.pinned {
    height: auto;
  }
}
